import {
  getGlobal,
  getAuthToken,
  getPropertyType,
  getUpdatedPropertyFeatures,
  setGlobalValue,
} from "./global-util";
import { getProp, hasProp, isObject, listSize, makeShortDateStamp, translateKeys } from "./util";
import { getHttpClient } from "./api";
import config from "../config";
import getLogger from "./debug-logger";

/*
Overlord API is used on both 'Property Details' and 'Review/Overlord' pages
*/

const log = getLogger("overlord-api", 1);

// refer to
// https://jira.optaintel.ca/confluence/display/SOA/Lambda+Services
// https://jira.optaintel.ca/confluence/pages/viewpage.action?spaceKey=PA&title=Overlord+Infrastructure+Requirements

// API endpoints have to be moved to config when enviroments are ready.
// Endpoint pattern:
// https://c7t6i2sbji.execute-api.ca-central-1.amazonaws.com/v1/overlord/condo/add
// https://c7t6i2sbji.execute-api.ca-central-1.amazonaws.com/v1/overlord/residential/add

const getReviewUrl = (propType, operation = "add") => {
  const suffix = propType === "Condominium" ? "condo" : "residential";
  return `${config.overlordEndpoint}overlord/${suffix}/${operation}`;
};

// features OSS/Overlord key translation
const ossKeymap = {
  ArchitecturalStyleType: "ArchitecturalStyle",
  SquareFootage: "TotalFloorAREASQFT",
  //GarageNumberOfCars: "GarageSize",
  StoreyCount: "BuildingStoreys",
  LotSize: "LotsizeSQFT",
  BathroomCount: "Bathrooms",
  NumberOfBedrooms: "Bedrooms",
  KitchenCount: "Kitchens",
  OutbuildingPresent: "Outbuilding",
  PrimaryHeatingType: "SpecialtyHeating",
  SwimmingPoolType: "Swimmingpool",

  //
  NumberOfStoreys: "BuildingStoreys",
  MultiResidentialStyleType: "ArchitecturalStyle",
  ParkingType: "Parking",
  NumberOfBathrooms: "Bathrooms",
  NumberOfDens: "Dens",
  TotalFloorArea: "TotalFloorAREASQFT",
};

// create request payload as a base for all operations
const createPayload = (data = {}) => {
  return {
    Token: getAuthToken(),
    ...data,
  };
};

// gather global data points and populate payload object
const buildPayloadFromGlobal = (extraData = {}) => {
  const glob = getGlobal();
  const addr = glob.ADDRESS_RESP_OBJECT;
  const feats = getUpdatedPropertyFeatures();
  const placeholder = "-";
  if ("GarageType" in feats && !hasProp(feats, "GarageNumberOfCars")) {
    feats.GarageNumberOfCars = "";
  }

  const propertyData = {
    optaaddresskey: glob.OAK || placeholder,
    PropertyType: glob.PROPERTY_TYPE,
    StreetAddress: glob.ADDRESS_LINE_STREET || glob.ADDRESS_LEGAL_LINE,
    City: getProp(addr, "Municipality") || placeholder,
    Province: getProp(addr, "Province"),
    PostalCode: getProp(addr, "PostalCode") || placeholder,
    MarketValuation: glob.AVM_VALUE,
    Confidence: glob.CONFIDENCE_RATE,
    DateUpdated: makeShortDateStamp(),
    UpdatedBy: glob.USERNAME,
    ...translateKeys(feats, ossKeymap),
    ...extraData,
  };
  // add Rental Type and Value, if activated by user
  if (listSize(glob.RENTAL_RANGE) === 2) {
    propertyData.RentalValuationType = glob.RENTAL_TYPE;
    propertyData.RentalValuationMin = Number(glob.RENTAL_RANGE[0]);
    propertyData.RentalValuationMax = Number(glob.RENTAL_RANGE[1]);
  }
  return createPayload(propertyData);
};

//------------------------------------------------------------
// send POST request to Overlord endpoints
//------------------------------------------------------------
const sendOverlordRequest = async (url, payload = {}) => {
  const conf = {
    method: "POST",
    url,
    timeout: 30000,
    validateStatus: null,
    data: payload,
  };
  conf.headers = {
    "Content-Type": "application/json",
  };
  const client = getHttpClient();
  //
  log(`Sending POST request to`, url, "REQUEST DATA >>>", conf.data, "<<< END"); //------ log
  //
  setGlobalValue("DATA_ERROR", null);
  let res = null,
    data = null,
    errMsg = "";

  try {
    res = await client.request(conf);

    data = getProp(res, "data");
  

    log("Overlord response received", "RESPONSE >>>", res, "<<< END"); //------ log

    if (!data) {
      errMsg = getProp(res, "Message") || "DATA_ERROR";
      log(`No data returned from Overlord API call`); //---------------------- log
    }

    errMsg = getProp(data, "message"); // error response
  } catch (x) {
    errMsg = (typeof x === "object" && x.message) || "DATA_ERROR";
  }
  if (errMsg) {
    log(`API message: ${errMsg}`); //---------------------- log
    setGlobalValue("DATA_ERROR", "DATA_ERROR");
    return null;
  }
  return data;
};

//------------------------------------------------------------
// submit property review
//------------------------------------------------------------
export const addPropertyReview = async (extraData = null) => {
  const propType = getPropertyType();
  const url = getReviewUrl(propType, "add");
  const payload = buildPayloadFromGlobal(extraData || {});
  const result = await sendOverlordRequest(url, payload);
  return result;
};

//------------------------------------------------------------
// list overlord items
//------------------------------------------------------------
export const listOverlordProperties = async () => {
  const url = `${config.overlordEndpoint}overlord/get`;
  const payload = createPayload();
  const result = await sendOverlordRequest(url, payload);
  return result;
};

//------------------------------------------------------------
// send Overlord data
//------------------------------------------------------------
export const updatePropertyReview = async (data) => {
  if (!isObject(data)) {
    log(`data object expected`);
    return null;
  }
  const propType = getProp(data, "PropertyType");
  const url = getReviewUrl(propType, "add");
  const payload = createPayload(data);
  const result = await sendOverlordRequest(url, payload);
  return result;
};
