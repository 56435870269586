import config from "../config";
import { getHttpClient } from "./api";
import {
  getGlobalValue,
  getAuthToken,
  getUser,
  isFrench,
  setError,
  getUpdatedPropertyFeatures,
  filterAvailableProducts,
  setGlobalValue,
  setGlobal,
} from "./global-util";
import { dropSession } from "./session";
import {
  listSize,
  getProp,
  digObject,
  makeNumericId,
  filterObject,
  strval,
  bool as utilBool,
  isObject,
  isEmpty,
  isNone,
  getTreeProp,
  strlen,
  jsonParse,
  arrayUnique,
  isEmptyObject,
  splitWords,
  inArray,
  isNumber,
  jsonStringify,
  makeShortDateStamp,
} from "./util";
import {
  extractComparables,
  extractMsResult,
  extractReportUrl,
  transformEnvironmental,
} from "./oss-ms-transfom";
import { makeAddressLine } from "./oss-util";
import getLogger, { debugLevel } from "./debug-logger";
import { setAndStoreGlobalValue, setAndStoreGlobals } from "./local-data";
import { isCondo } from "./features-config";
import { getPolMarker, translateFeatKey } from "./features-sources";

const log = getLogger("oss-ms-api", 2);

const { ossMsApiEndpoint, ossApiTimeout } = config;

//------------------------------------------------------------
// Utility methods for OSS microservices API
//------------------------------------------------------------

// transform address to a form expected by microservice
const buildMsRequestAddress = () => {
  const addrObj = getGlobalValue("ADDRESS_RESP_OBJECT");
  const reqAddr = {
    Line: makeAddressLine(addrObj),
    Municipality: { Name: addrObj.Municipality, StateProvince: { Code: addrObj.Province } },
    PostalCode: addrObj.PostalCode,
  };
  return filterObject(reqAddr);
};

// falsy values shouldn't be submitted if they belong missing data points
const isMissingKey = ((k) => {
  let missingKeys = null;
  return () => {
    if (isNone(missingKeys)) missingKeys = splitWords(getGlobalValue("FEATURES_MISSING"));
    return inArray(missingKeys, k);
  };
})();

const getVal = (obj, k) => {
  const v = getProp(obj, k);
  return isNone(v) || (v === "" && isMissingKey(k)) ? null : v;
};

const getStr = (obj, k) => {
  const v = getVal(obj, k);
  return isNone(v) ? null : strval(v);
};

const getNum = (obj, k) => {
  const v = getVal(obj, k);
  return isNone(v) ? null : Number(v) || 0;
};

const getBool = (obj, k) => {
  const v = getVal(obj, k);
  return isNone(v) ? null : utilBool(v);
};

//------------------------------------------------------------
// collect Residential construction features, filter off nulls
//------------------------------------------------------------
const buildResidentialFeatures = (feats, container = {}) => {
  if (!isObject(feats) || isEmpty(feats)) return container;
  const updFeats = getUpdatedPropertyFeatures();
  if (isEmpty(updFeats)) return container;

  const userFeats = {
    ResidentialBuilding: {
      UserProvidedResidentialConstructionFeatures: {
        ArchitecturalStyleTypeCode: getStr(feats, "ArchitecturalStyleType"),
        AreaUnitOfMeasurementCode: "SquareFeet",

        BathroomConstruction: {
          BathroomCountTypeCode: getStr(feats, "BathroomCount"),
        },
        BedroomConstruction: {
          NumberOfBedrooms: getNum(feats, "NumberOfBedrooms"),
        },
        BuildingStoreyConstruction: {
          StoreyCountTypeCode: getStr(feats, "StoreyCount"),
        },
        CommercialIndicator: getBool(feats, "CommercialIndicator"),
        ExteriorWallConstructions: [
          {
            ExteriorWallTypeCode: getStr(feats, "ExteriorWallType"),
          },
        ],
        FinishedBasement: getBool(feats, "FinishedBasement"),
        FoundationTypeCode: getStr(feats, "FoundationType"),
        Garages: [
          {
            GarageTypeCode: getStr(feats, "GarageType"),
            NumberOfCars: getNum(feats, "GarageNumberOfCars"),
          },
        ],
        KitchenConstruction: {
          KitchenCountTypeCode: getStr(feats, "KitchenCount"),
        },
        OutbuildingPresentCode: getStr(feats, "OutbuildingPresent"),
        PlumbingTypeCode: getStr(feats, "PlumbingType"),
        PrimaryHeatingTypeCode: getStr(feats, "PrimaryHeatingType"),
        RoofConstructions: [
          {
            RoofTypeCode: getStr(feats, "RoofType"),
          },
        ],
        SquareFootage: getNum(feats, "SquareFootage"),
        SwimmingPoolTypeCode: getStr(feats, "SwimmingPoolType"),
        YearBuilt: getNum(feats, "YearBuilt"),
        ViewCode: getStr(feats, "View"),
        WaterfrontCode: getStr(feats, "Waterfront"),
      },
    },
    Property: {
      UserProvidedPropertyFeatures: {
        LotSize: getNum(feats, "LotSize"),
        LotDepth: getNum(feats, "LotDepth"),
        LotFrontage: getNum(feats, "LotFrontage"),
      },
    },
  };
  return Object.assign(container, filterObject(userFeats, true));
};

//------------------------------------------------------------
// collect Condo Unit & Building construction features, filter off nulls
//------------------------------------------------------------
const buildCondoFeatures = (feats, container = {}) => {
  if (!isObject(feats)) return container;
  const updFeats = getUpdatedPropertyFeatures();
  if (isEmpty(updFeats)) return container;

  const unit = {
    MultiResidentialUnit: {
      FloorLevel: getNum(feats, "FloorLevel"),
      Structure: {
        StructureClassCode: "MultiResidential",
        ConstructionFeatures: {
          YearBuilt: getNum(feats, "YearBuilt"),
          NumberOfStoreys: getNum(feats, "NumberOfStoreys"),
        },
      },
      ConstructionFeatures: {
        TotalFloorArea: getNum(feats, "TotalFloorArea"),
        AreaUnitOfMeasurementCode: "SquareFeet",
        BedroomConstruction: {
          NumberOfBedrooms: getNum(feats, "NumberOfBedrooms"),
          NumberOfDens: getNum(feats, "NumberOfDens"),
        },
        BathroomConstruction: {
          BathroomCountTypeCode: getStr(feats, "NumberOfBathrooms"),
        },
      },
    },
  };
  //
  const prop = {
    UserProvidedPropertyFeatures: {
      Utilities: [
        {
          SewageServiceCode: getStr(feats, "SewageType"),
          WaterServiceCode: getStr(feats, "WaterType"),
        },
      ],
      Building: [
        {
          MultiResidentialBuilding: {
            MultiResidentialUnit: {
              ResidentialConstructionFeatures: {
                TotalFloorArea: getNum(feats, "TotalFloorArea"),
                AreaUnitOfMeasurementCode: "SquareFeet",
                ViewCode: getStr(feats, "View"),
                GarageNumberOfCars: getNum(feats, "GarageNumberOfCars"),
                Balcony: getBool(feats, "Balcony"),
                Locker: getBool(feats, "Locker"),
                BedroomConstruction: {
                  NumberOfBedrooms: getNum(feats, "NumberOfBedrooms"),
                  NumberOfDens: getNum(feats, "NumberOfDens"),
                },
                BathroomConstruction: {
                  BathroomCountTypeCode: getStr(feats, "NumberOfBathrooms"),
                },
              },
              FloorLevel: getNum(feats, "FloorLevel"),
            },
            CommercialConstructionFeatures: {
              YearBuilt: getNum(feats, "YearBuilt"),
              StructureStyleTypeCode: getStr(feats, "MultiResidentialStyleType"),
              NumberOfStoreys: getNum(feats, "NumberOfStoreys"),
              ExteriorWallTypes: [
                {
                  CommercialExteriorWallTypeCode: getStr(feats, "ExteriorWallType"),
                },
              ],
              RoofAttributes: {
                CommercialRoofSurfaces: [
                  {
                    CommercialRoofSurfaceTypeCode: getStr(feats, "RoofSurface"),
                  },
                ],
              },
              Plumbing: {
                CommercialPlumbingTypes: [
                  {
                    CommercialPlumbingTypeCode: getStr(feats, "PlumbingType"),
                  },
                ],
              },
              Parking: {
                CommercialParking: [
                  {
                    ParkingTypeCode: getStr(feats, "ParkingType"),
                  },
                ],
              },
            },
            MultiResidentialStyleTypeCode: getStr(feats, "MultiResidentialStyleType"),
          },
        },
      ],
    },
  };

  return Object.assign(container, filterObject(unit, true), filterObject(prop, true));
};

const buildUserUpdates = () => {
  const propType = getGlobalValue("PROPERTY_TYPE");
  const feats = getUpdatedPropertyFeatures();
  return isCondo(propType) ? buildCondoFeatures(feats) : buildResidentialFeatures(feats);
};

// combine request data with common presets
const buildOssMsAttributes = (dataObj) => {
  return {
    Username: getUser(),
    RequestorID: makeNumericId(),
    ImageryProvider: [
      {
        ImageryProviderTypeCode: "ILookabout",
      },
      {
        ImageryProviderTypeCode: "Google",
      },
    ],
    ...dataObj,
  };
};

// common OSS microservice request object
const buildOssMsRequest = (prodList, attrObj = null, userData = null) => {
  if (!listSize(prodList)) {
    log(`no products specified`, prodList); //------- log
    return null;
  }
  prodList = arrayUnique(prodList);
  prodList = filterAvailableProducts(prodList, true);
  if (!listSize(prodList)) {
    log(`requested products not available for this user`); //------- log
  }

  return {
    microservice: "opta-service-bus",
    headers: {
      "Accept-Language": isFrench() ? "fr" : "en",
    },
    params: {
      Products: prodList.join(),
    },
    body: {
      ProductRequest: [
        {
          Address: buildMsRequestAddress(),
          ...(userData || {}),
        },
      ],
      UserAttributes: buildOssMsAttributes(attrObj),
    },
  };
};

//----------------------------------------------------------
// send MS POST request
//----------------------------------------------------------
export const ossMsPost = async (postData) => {
  let errorMsg = "";
  const conf = {
    method: "POST",
    url: ossMsApiEndpoint,
    validateStatus: null, // resolve error responses
    data: postData,
  };
  const token = getAuthToken();
  if (!token) {
    log("Access token not available"); //--------------- log
    return null;
  }
  conf.headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  const client = getHttpClient(ossApiTimeout);
  log(`Sending POST request to`, conf.url, "REQUEST DATA >>>", conf.data, "<<< END"); //-------- log
  try {
    const res = await client.request(conf);
    const data = getProp(res, "data");
    if (!data) {
      log(`No data returned from OSS microservice API call`); //----------------- log
    }
    const status = getProp(res, "status", 0);
    const statusText = getProp(res, "statusText", "error");
    errorMsg = digObject(res, "data", "error") || getTreeProp(res, "Message") || "";
    if (status === 401 || errorMsg === "invalid_token") {
      log(`${status} ${statusText}`); //----------------------- log
      errorMsg = "Access denied";
      log(errorMsg); //----------------------- log
      setError(errorMsg);
      dropSession();
      return res;
    }
    // remove noise from debug array if debug level is 1
    if (debugLevel === 1 && !!getTreeProp(data, "Results", 0, "Result", "Debug")) {
      log(`stripping noisy debug info (use ?dbg=2 or higher to keep it)`); //----------------------- log
      delete data.Results[0].Result.Debug;
    }

    log("Microservice response received", "RESPONSE DATA >>>", data, "<<< END"); //---------- log
    return data;
  } catch (x) {
    const msg = (typeof x === "object" && x.message) || x + "";
    log(msg); //----------------- log
  }
  return null;
};

// Property Stats JSON translator
const translateStatsJson = (statsJson) => {
  const stats = jsonParse(statsJson);
  // translate some keys
  ["PropertyOccupancy", "Tenure", "ZoningDescription"].forEach((k) => {
    let kk = k + "TypeCode";
    stats[kk] = stats[k];
    delete stats[k];
  });
  return stats;
};

//----------------------------------------------------------
// fetch Comparables only or PDF Report with all related products
//----------------------------------------------------------
const updateComparablesData = async () => {
  const radius = Number(getGlobalValue("COMPARABLES_RADIUS"));
  const history = Number(getGlobalValue("COMPARABLES_HISTORY"));
  const maxNum = Number(getGlobalValue("COMPARABLES_NUMBER"));
  if ((!radius && radius !== 0) || !history || !maxNum) {
    log(`missing required configuration values`); //------ log
    return null;
  }
  setGlobal({ REPORT_COMP_URL: null, COMPARABLES_ERROR: null });

  // pass list of OAKs as reportParam to get report URL as well
  const oakList = getGlobalValue("COMPARABLES_OAKS"); // comma-separated OAKs as string
  // should the call include Rental product?
  const hasRental = !!getGlobalValue("RENTAL_REQUESTED");
  const hasAvm = !!getGlobalValue("AVM_REQUESTED");
  const hasRange = !!getGlobalValue("VALUE_RANGE_REQUESTED");
  const hasEnv = !!getGlobalValue("ENV_REQUESTED");
  const floodMapConf = hasEnv ? jsonParse(getGlobalValue("FLOOD_MAP_JSON")) : undefined;
  const hasFlood = !!floodMapConf;

  const prodList = strlen(oakList)
    ? [
        "MarketValueComparables",
        "iClarifyFinancialResidentialReport",
        hasAvm && "ResidentialMarketValuation",
        hasRange && "EstimatedValueRange",
        hasRental && "RentalValuation",
        (hasAvm || hasRental) && "MarketValuationConfidence",
        "PropertyListings",
        "PropertySales",
        "AssessmentListings",
        "RentalListings",
        "PropertyStats",
        "NeighbourhoodName",
        "MarketMetrics",
        "HPIHistorical",
        "OptaPermitFinancial",
        hasEnv && "WildfireIndex",
        hasEnv && "ActiveWildfires",
        hasEnv && "FUS",
        hasFlood && "FloodScore",
      ].filter(Boolean)
    : ["MarketValueComparables"];

  const customAttr = {
    ComparablesSearchHistoryDays: history * 30, // months to days,
    ComparablesProperties: oakList || "",
    NumberOfComparables: maxNum,
  };
  if (radius && isNumber(radius)) customAttr.ComparablesRadiusMetres = radius * 1000; // km to m
  //
  let updates = buildUserUpdates() || {};
  let prop = getProp(updates, "Property") || {};

  prop.HistoricalDate = makeShortDateStamp(); // today's date as 'YYYY-MM-DD' (e.g. '2024-07-25')

  if (hasRental) {
    prop = { ...prop, RentalUnitTypeCode: getGlobalValue("RENTAL_TYPE") };
  }

  // if stats were updated
  const updStatsJson = getGlobalValue("STATS_UPDATED");
  if (updStatsJson) {
    prop.PropertyStats = translateStatsJson(updStatsJson);
  }

  if (hasFlood) {
    log(`adding flood map configuration`, floodMapConf); //---------- log
    prop.FloodMap = floodMapConf;
  }

  if (!isEmpty(prop)) updates.Property = prop;

  const reqObj = buildOssMsRequest(prodList, customAttr, updates);

  if (!reqObj) {
    log(`request object cannot be produced`); //------------------- log
    return null;
  }
  const data = await ossMsPost(reqObj);
  if (!data) {
    return null;
  }
  return extractMsResult(data);
};

//----------------------------------------------------------
// fetch Report URL based on selected Comparables
//----------------------------------------------------------
export const updateCompsReportUrl = async () => {
  const result = await updateComparablesData();
  const reportUrl = extractReportUrl(result);
  log(`report URL from Microservice: '${reportUrl}'`); //-------------- log

  setGlobalValue("REPORT_COMP_URL", reportUrl || "");
  return reportUrl;
};

//----------------------------------------------------------
// fetch Comparables and update global list
//----------------------------------------------------------
export const loadComparables = async () => {
  const products = await updateComparablesData();

  const comps = extractComparables(products) || [];
  const size = listSize(comps);

  log(`${size} comparables returned`); //----------- log

  setGlobalValue("COMPARABLES", comps);
  return comps;
};

//----------------------------------------------------------
// fetch environmental and accompanying products
//----------------------------------------------------------
const fetchMsProducts = async (productArray, attrObj = null, propObj = null) => {
  setGlobal({
    FLOODSCORE_ERROR: null,
    WILDFIRE_ERROR: null,
    FUS_ERROR: null,
    REPORT_ERROR: null,
    REPORT_URL: null,
  });

  const hasRental = !!getGlobalValue("RENTAL_REQUESTED");
  const hasAvm = !!getGlobalValue("AVM_REQUESTED");
  const hasRange = !!getGlobalValue("VALUE_RANGE_REQUESTED");

  const allProducts = [
    ...productArray,
    hasAvm && "ResidentialMarketValuation",
    hasRange && "EstimatedValueRange",
    hasRental && "RentalValuation",
    (hasAvm || hasRental) && "MarketValuationConfidence",
    "PropertyListings",
    "PropertySales",
    "AssessmentListings",
    "RentalListings",
    "PropertyStats",
    "NeighbourhoodName",
    "MarketMetrics",
    "HPIHistorical",
    "OptaPermitFinancial",
  ].filter(Boolean);

  let updates = buildUserUpdates() || {};
  let prop = getProp(updates, "Property") || {};

  if (isObject(propObj)) {
    Object.assign(prop, propObj); // merge Property objects
  }

  prop.HistoricalDate = makeShortDateStamp(); // for HPIHistorical

  if (hasRental) {
    prop = { ...prop, RentalUnitTypeCode: getGlobalValue("RENTAL_TYPE") };
  }

  const updStatsJson = getGlobalValue("STATS_UPDATED");
  if (updStatsJson) {
    prop.PropertyStats = translateStatsJson(updStatsJson);
  }

  if (!isEmpty(prop)) updates.Property = prop;

  const reqObj = buildOssMsRequest(allProducts, attrObj, updates);

  if (!reqObj) {
    log(`request object cannot be produced`); //------------------- log
    return null;
  }
  const data = await ossMsPost(reqObj);
  if (!data) {
    return null;
  }
  return extractMsResult(data);
};

//----------------------------------------------------------
// fetch Wildfire Score (not available via OSS)
//----------------------------------------------------------
export const loadEnvProducts = async (floodMapOpts = null) => {
  setAndStoreGlobalValue("ENV_REQUESTED", true);

  let mapConf = floodMapOpts;
  if (!isObject(floodMapOpts)) {
    mapConf = jsonParse(getGlobalValue("FLOOD_MAP_JSON"));
  }

  // FloodScore is only called if floodMapOpts is an object
  const includeFlood = isObject(mapConf);

  setGlobal({
    FLOODSCORE_ERROR: null,
    WILDFIRE_ERROR: null,
    FUS_ERROR: null,
    ACTIVE_WILDFIRES_ERROR: null,
    REPORT_ERROR: null,
    REPORT_URL: null,
  });
  const products = [
    "WildfireIndex",
    "ActiveWildfires",
    "FUS",
    "iClarifyFinancialResidentialReport",
    includeFlood && "FloodScore",
  ].filter(Boolean);
  //
  const reqProps = includeFlood ? { FloodMap: mapConf } : undefined;

  let res = await fetchMsProducts(products, null, reqProps);

  if (!res) {
    log(`no Environmental data returned`, res); //--------------- log
    setGlobal({
      FLOODSCORE_ERROR: includeFlood ? "FLOODSCORE_ERROR" : null,
      WILDFIRE_ERROR: "WILDFIRE_ERROR",
      FUS_ERROR: "FUS_ERROR",
    });
    return;
  }
  const updates = transformEnvironmental(res);

  if (!isEmptyObject(updates)) {
    log(`updating and caching globals`, updates); //----------- log
    setAndStoreGlobals(updates);

    // store flood map config, if supplied
    if (isObject(floodMapOpts)) {
      log(`storing flood map configuration`); //------------------------------- log
      setAndStoreGlobalValue("FLOOD_MAP_JSON", jsonStringify(floodMapOpts));
    }
  }
};

//----------------------------------------------------------
// fetch sources for property features
//----------------------------------------------------------
export const loadFeatSources = async () => {
  const propType = getGlobalValue("PROPERTY_TYPE");
  if (!propType) {
    log(`no property type, features sources API call dropped`); //------------------ log
  }

  const condo = isCondo(propType);

  // validate and transform requested product name (1 = microservice call)
  const osbProdNames = filterAvailableProducts(["iClarifyFinancialResidentialPrefill"], 1);
  const reqObj = {
    microservice: "opta-service-bus",
    headers: {
      "Accept-Language": isFrench() ? "fr" : "en",
    },
    params: {
      Products: osbProdNames.join(),
    },
    body: {
      ProductRequest: [
        {
          Address: buildMsRequestAddress(),
        },
      ],
    },
  };

  const data = await ossMsPost(reqObj);
  if (!data) {
    return null;
  }

  const dataKey = condo ? "FeaturesSources" : "FeatureSources";

  const sources = getTreeProp(data, dataKey);
  log("FeatureSources", sources); //------------------------------------ log

  if (!listSize(sources)) {
    log(`could not retrieve features sources`); //------------------ log
    return null;
  }

  const featKey = condo ? "CondoConstructionFeatureCode" : "ResidentialConstructionFeatureCode";
  const sourceKey = condo
    ? "CondoConstructionFeatureSourceCode"
    : "ResidentialConstructionFeatureSourceCode";
  const sourceMapObj = {};

  sources.forEach((it) => {
    let featCode = getProp(it, featKey);
    if (!featCode) return null;
    const srcCode = getProp(it, sourceKey);
    if (!srcCode) return null;
    // translate OSB to OSS key
    featCode = translateFeatKey(featCode, condo);
    sourceMapObj[featCode] = getPolMarker(srcCode, condo);
  });
  log(`setting features sources globally`, sourceMapObj); //----------- log
  setAndStoreGlobalValue("FEATURES_SOURCES", sourceMapObj);
  return sourceMapObj;
};
