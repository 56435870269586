const labels = {
  //----- confidence values
  high: "haute",
  medium: "moyen",
  low: "bas",
  //-----------------------

  true: "Oui",
  false: "Non",
  //-----------------------
  "ACORN Stove / Box / Franklin / Pot Belly Stove":
    "Poêle ACORN, poêle-cuisinière, poêle Franklin ou poêle ventru",
  "Above Ground Fibre Glass": "Hors-terre - fibre de verre",
  "Above Ground": "Stationnement en surface",
  "Address not found": "ADRESSE INTROUVABLE",
  Aggregate: "Granulat",
  Agricultural: "Agriculture",
  "Aluminum Siding": "Parement d'aluminium",
  Aluminum: "Aluminium",
  ArchitecturalStyleType: "Style de bâtiment",
  "Architectural Style": "Style de bâtiment",
  "Asphalt Roll": "Rouleau d'asphalte",
  "Asphalt Shingles": "Bardeaux d'asphalte",
  Asphalt: "Asphalte",
  "Attached Garage": "Garage attaché",
  //AuxiliaryHeatingType: "Chauffage d'appoint",
  "Average List Price": "Prix de liste moyen",
  "Average Sale Price": "Prix de vente moyen",
  "Average Sold Price": "Prix de vente moyen",

  Balcony: "Balcon",
  "Baseboard Heater": "Plinthe électrique",
  "Basement Garage": "Garage au sous-sol",
  Basement: "Sous-sol",
  BathroomCount: "Salles de bains",
  "Bi-Energy Combination": "Bi-énergie",
  BiLevel: "à deux niveaux",
  "Brick / Masonry Veneer": "Brique/placage en maçonnerie",
  "Brick Veneer": "Parement de briques",
  Brick: "Brique",
  "Built-in Garage": "Garage integré",
  Ceramic: "Céramique",
  "Clay Tile": "Tuiles d'argile",
  Clear: "Effacer",
  Combined: "Combiné",
  CommercialIndicator: "Indicateur commercial",
  Comparables: "Comparables",
  ComparablesNotAvailable: "Aucune propriété comparable n'est disponible pour le moment.",
  "Compare View": "Comparer la vue",
  "Complete Property": "Propriété complète",
  "Concrete Block": "Blocs de béton",
  "Concrete Tile": "Tuile de béton",
  Concrete: "Béton",
  "Condo Building Features": "Caractéristiques de l'immeuble en copropriété",
  "Condo Unit Features": "Caractéristiques de la copropriété",
  Condominium: "Copropriété",
  Confidence: "Confidence",
  Copper: "Cuivre",
  "Corrugated Steel": "Acier ondulé",
  Crawlspace: "Vide sanitaire",
  Decreasing: "Diminution de",
  Detached: "Détaché",
  "Discard Changes?": "Supprimer les changements?",
  DistanceFromSubjectProperty: "Distance par rapport à l'objet de l'enquête (kms)",
  Easement: "Servitude",
  "Electric Furnace": "Fournaise électrique",
  Electric: "Électrique",
  //"End Row": "Fin de rangée",
  "End Row": "Rangée de fin",
  EnterAddress: "Entrer une adresse ou un code postal",
  "Estimated Rent (per month)": "Loyer estimé (par mois)",
  "Estimated Value Range": "Fourchette de valeurs estimées",
  "Estimated Value": "Valeur estimée",
  ExteriorWallType: "Murs extérieurs",

  "FCT Property Search is powered by:": "FCT Property Search est alimenté par :",

  "Fee Simple": "Frais simples",
  Fibreboard: "Revêtement en fibrociment",
  FinishedBasement: "Sous-sol fini",
  Fireplace: "Foyer",
  Fiveplex: "Cinqplex",
  "Floor Furnace": "Poêle-cheminée",
  FloorLevel: "Niveau du sol",
  FoundationType: "Type de fondation",
  Fourplex: "Quadruplex",
  Freehold: "Propriété libre",
  "Full Address": "Adresse complète",
  "Furnace (Central) with Add-On Woodburning Unit":
    "Chauffage central avec unité de chauffage au bois supplémentaire",
  "Furnace (Central)": "Chauffage central",
  Galvanized: "Galvanisé",
  GarageNumberOfCars: "Taille du garage",
  GarageType: "Type de garage",
  Geothermal: "Géothermique",
  Glass: "Verre",
  Go: "Aller",
  "Green Roof": "Toit vert",
  HeatingType: "Type de chauffage",
  "Heat Pump": "Pompe à chaleur",
  "High Rise": "Haut de gamme",
  "Hot Water / Steam / Boiler Unit": "Unité eau chaude / vapeur / chaudière",
  Hot: "Chaude",
  "House Price Index": "Indice des prix des logements", // market metrics chart
  Increasing: "Augmentation de",
  "In Row": "En rangée",
  Industrial: "Industriel",
  "Inground Concrete": "En béton",
  "Inground Fibre Glass": "Creusée - fibre de verre",
  "Inground Vinyl": "Membrane vinyle",
  Iron: "Fonte/fer",
  KitchenCount: "Cuisines",
  Kitchens: "Cuisines",
  "Laminated Shingles": "Bardeaux laminés",

  Lead: "Plomb",
  Leasehold: "Bail",
  "Legal Description": "Description légale",
  "Listing Trend": "Tendance de la liste",
  Logs: "Bois",
  LotDepth: "Profondeur du terrain (pieds)",
  Locker: "Casier",
  LotFrontage: "Frontage du terrain (pieds)",
  LotSize: "Dimension du lot (pi2)",
  "Low Rise": "Bas niveau",
  "Map View": "Vue de la carte",
  "Market Temperature": "Température du marché",
  "Market Valuation": "Évaluation marchande",
  "Market Metrics": "Mesures du marché",
  "Metal Siding": "Bardage métallique",
  "Metal Tile": "Tuile métallique",
  Metal: "Métal",
  "Mineral Fiber Shakes": "Bardeaux de fente en fibres minérales",
  "Mixed Use": "Utilisation mixte",
  More: "Plus",
  "Multi Family - Residential": "Résidentiel multifamilial",
  MultiResidentialStyleType: "Style de bâtiment",
  Municipal: "Municipal",
  Municipality: "Municipalité",
  "Municipal Code": "Code municipal",
  NBathrooms: "%1 Salles de bains",
  NBedrooms: "%1 Chambres à coucher",
  NoGarage: "Pas de Garage",
  NCarsGarage: "%1 Garage plus d'une place",
  "Natural Gas Forced Air": "Chauffage au gaz naturel par air pulsé",
  "New Search": "Nouvelle recherche",
  "Neighbourhood": "Voisinage",
  "Neighborhood": "Voisinage",
  No: "Non",
  None: "Aucun",
  none: "aucun",

  NoSearchResultsMessage:
    "Continuez à taper l'adresse complète ou le code postal - Nous pouvons encore vous aider à trouver votre bien.",

  "Not entered": "Non entré",
  NumberOfBathrooms: "Salles de bains",
  NumberOfBedrooms: "Chambres à coucher",
  NumberOfDens: "Coins de détente",
  NumberOfStoreys: "Nombre d'étages",

  Oil: "Mazout",
  OneBathroom: "1 Salle de bains",
  OneBedroom: "1 Chambre à coucher",
  OneCarGarage: "1 Garage une place",
  OptaAddressKey: "Clé d'adresse Opta",
  Other: "Autres",
  OutbuildingPresent: "Présence d'un bâtiment auxiliaire",
  Overlord: "Suzeraine",
  "Owner Occupied": "Occupé par le propriétaire",
  "Parking Garage": "Stationnement intérieur",
  ParkingType: "Type de parking",
  "Partially Unheated": "Partiellement non chauffé",
  "Pellet Stove": "Poële à granules",
  Pier: "Quai",
  Plastic: "Plastique",
  "Plumbing Type": "Type de plomberie",
  PlumbingType: "Type de plomberie",
  PrimaryHeatingType: "Type de chauffage",
  "Price Trend": "Tendance des prix",
  Private: "Privé",
  "Property Details": "Détails de la propriété",
  "Property Features": "Caractéristiques de la propriété",
  "Property Identifier": "Identifiant de propriété",

  PropertyOccupancy: "Type d'occupation",

  "Property Search": "Recherche de propriété",
  "Properties Sold Over Asking": "Propriétés vendues au-dessus du prix demandé",
  "Property Type": "Type de propriété",
  "Radiant Ceiling": "Plafond chauffant",
  "Radiant Floor": "Plancher chauffant",
  "Radiant Heat": "Chaleur rayonnante",
  Radiant: "Chauffage radiant",
  "Reason for Review": "Raison de l'examen",
  Recalculate: "Recalculer",

  "Recalculate Range": "Recalculer la fourchette", // to be removed
  "Recalculate Value": "Recalculer la valeur", // to be removed

  Recreational: "Récréatif",
  Residential: "Résidentiel",

  RefreshMessage: "Cliquez sur Actualiser la page pour effectuer une nouvelle recherche.",
  "Refresh Page": "Actualiser la page",
  Rental: "Location",
  "Rental Activity": "Activité de location",
  "Rental Unit Type": "Type d'unité locative",
  Report: "Rapport",
  Reset: "Réinitialiser",
  RoofSurface: "Surface du toit",
  RoofType: "Type de toiture",
  "Rubber Membrane": "Membrane de caoutchouc",
  Rubber: "Caoutchouc",
  "Sales Trend": "Tendance des ventes",
  Save: "Enregistrer",
  "Semi Detached": "Semi-détaché",
  "Search Tips": "Astuces de recherche",
  "Select Rental Type": "Sélectionnez le type de location",

  SearchTip1: "Recherchez les numéros d'unité comme préfixe. %1",
  SearchTip2: "Rechercher la direction de la rue après le nom de la rue. %1",
  SearchTip3: "Recherche avec le nom de la rue avant le type de route au Québec. %1",

  SepticSystem: "Fosse septique",
  SewageType: "Type d'eaux usées",
  "Sign Out": "Fermer la session",
  "Single Family - Residential": "Résidentiel unifamilial",
  "Slab on Grade": "Dalle sous-sol",
  "Slate Tile": "Tuile d'ardoise",
  "Slow Wood Burning / Air Tight Stove": "Poêle à bois à combustion lente/poêle hermétique",
  Snapshot: "Aperçu",
  "Solar Panels": "Panneaux solaires",
  "Sold Date": "Date de vente",
  "Sold Price": "Prix de vente",
  Sold: "Vendu",
  "Solid Fuel Heating Unit": "Unité de chauffage à combustible solide",
  "Space Heater": "Chauffage d'appoint",
  SquareFootage: "Superficie totale (pi2)",
  Stable: "Stabilité",
  Steel: "Acier",
  "Stone Veneer": "Parement de pierres",
  Stone: "Pierre",
  StoreyCount: "Nombre d'étages",
  "Street View": "Vue de la rue",
  Stucco: "Stuc",
  "Submit for Review": "Soumettre pour correction",
  Support: "Soutien",
  SwimmingPoolType: "Piscine",
  "Tar and Gravel": "Goudron et gravier",
  Tenure: "Titularisation",
  Tin: "Étain",
  Title: "Caractéristiques de l'établissement",
  TotalFloorArea: "Dimension du lot (pi2)",
  TriLevel: "à trois niveaux",
  Underground: "Stationnement souterrain",
  Unknown: "Inconnu",
  UnknownGarage: "Garage inconnu",
  Update: "Mise à jour",
  "Vinyl Siding": "Bardage en vinyle",
  "Walkout Basement": "Maison avec sous-sol à entrée",
  "Wall Furnace": "Chauffage mural",
  "Valuation (AVM)": "Valorisation (MAV)",
  "Valuation Information": "Informations d'évaluation",
  View: "Voir",
  Water: "Eau",
  Waterfront: "Bord de mer",
  WaterType: "Type d'eau",
  "Wood Furnace": "Fournaise au bois",
  "Wood Shingles": "Bardeaux de bois",
  "Wood Siding": "Parement de bois",
  Wood: "Bois",
  Woodstove: "Poële à bois",
  YearBuilt: "Année de construction",
  Yes: "Oui",
  "Forgot Password": "Mot de passe oublié",
  Username: "Nom d'utilisateur",
  "User Name": "Nom d'utilisateur",
  "Enter your username": "Entrez votre nom d'utilisateur",
  "Go back to login page": "Retournez à la page de connexion",
  ZoningDescription: "Description du zonage",

  ":": " :",

  //------------------------------------
  Enter: "Entrer",
  Loading: "Chargement",
  ForgotPasswordSuccess: "Un e-mail a été envoyé dans votre boîte de réception",
  ForgotPasswordError:
    "Une erreur s'est produite; si cela persiste, contactez le soutien technique.",
  ResetPasswordSuccess:
    "Votre mot de passe a été réinitialisé avec succès. Retour à la page de connexion",
  "Reset Password": "Réinitialiser le mot de passe",
  Password: "Mot de passe",
  "Enter your new password": "Entrez votre nouveau mot de passe",
  EnterYourPassword: "Entrez votre mot de passe",
  "Confirm Password": "Confirmez le mot de passe",
  "Incorrect Username or Password": "Identifiant ou mot de passe incorrect",
  Login: "Connexion",
  "Name is required": "Nom requis",
  "Email is required": "Courriel requis",
  "Invalid email": "Courriel invalide",
  "Message is required": "Message requis",
  ContactUsSuccess: "Votre message a été envoyé. Nous vous contacterons sous peu",
  "Contact Us": "Contactez-nous",
  Name: "Nom",
  "Enter your name": "Entrez votre nom",
  Email: "Courriel",
  "Enter your email": "Entrez votre courriel",
  Message: "Message",
  "Enter your message": "Entrez votre message",
  Submit: "Soumettre",

  //------------------------ Valuation+ entries

  Assessment: "Évaluation",
  Valuation: "Valorisation",
  "Listings / Sales History": "Inscriptions / Historique des ventes",
  History: "Histoire",
  Listing: "Inscription",
  Price: "Prix",
  "Rental Listing": "Liste des locations",
  Sale: "Vente",
  "Permit Type": "Type de permis",
  "Date Issued": "Date d'émission",
  Value: "Valeur",
  "Work Description:": "Description du travail :",
  "Permit #:": "Permis # :",
  Completed: "Terminé",
  Cancelled: "Annulé",
  Closed: "Fermé",
  Expired: "Expiré",
  Denied: "Refusé",
  "In progress": "En cours",
  //Other: "Autres", // duplicate

  "Property Transaction History": "Historique des transactions immobilières",
  "Property Permit History": "Historique des permis de construire",

  HOUSE_PRICE_INFO:
    "Indice des prix des logements basé sur le prix de vente moyen actuel dans le quartier, indexé sur 12 mois sur la base des tendances historiques.",

  // Custom error messages
  DATA_ERROR: "Erreur de données",
  PREFILL_ERROR:
    "Les caractéristiques de construction du bien ne sont pas disponibles pour le moment",
  IMAGERY_ERROR: "Les données d'imagerie ne sont pas disponibles pour le moment",
  AVM_ERROR: "L'évaluation du marché n'est pas disponible",
  RENTAL_VALUE_ERROR: "Évaluation des loyers non disponible",

  AVM_FAILED: "Aucune information sur l'évaluation n'est disponible pour ce bien",

  LISTINGS_ERROR: "Aucune information n'est disponible pour cette propriété.",
  SALES_ERROR: "Aucune information sur la vente n'est disponible pour cette propriété.",
  ASSESMENTS_ERROR: "Aucune information d'évaluation disponible pour cette propriété",
  RENTALS_LIST_ERROR: "Aucune information sur la location n'est disponible pour cette propriété",
  STATS_ERROR:
    "Aucune information sur les statistiques de la propriété n'est disponible pour cette propriété",
  METRICS_ERROR: "Aucun indicateur de marché n'est disponible pour l'instant",

  //---------------------- Advanced search labels
  Advanced: "Avancé",
  "Quarter/LSD": "Trimestre/LSD",
  Township: "Canton",
  Range: "Gamme",
  Meridian: "Méridien",

  // ---------------------- Environmental view
  "CRESTA Accumulation Zone": "Zone d'accumulation du CRESTA",
  Environmental: "Environnement",
  "Earthquake Risk": "Risque sismique",
  "Flood Score": "Score d'inondation",
  "Flood Map": "Carte des inondations",
  "Wildfire Score": "Score de feu de forêt",
  "%1 years": "%1 ans",
  "Extreme Risk": "Risque extrême",
  "High Risk": "Risque élevé",
  "Low Risk": "Risque faible",
  "Medium Risk": "Risque moyen",
  "West very low": "Ouest très faible",
  years: "ans",

  // back end description strings translated
  "West very low (British Columbia ex zones W1-W5)":
    "Ouest très faible (Colombie-Britannique ex zones W1-W5)",
  "Ontario very low": "Ontario très faible",
  "Quebec very low": "Québec très faible",
  "Quebec very low (excluding zones E1 - E5)": "Québec très faible (hors zones E1 - E5)",
  "Maritime provinces": "Provinces maritimes",
  Newfoundland: "Terre-Neuve",
  "Yukon Territories": "Territoires du Yukon",
  "North West Territories and Nunavut": "Territoires du Nord-Ouest et Nunavut",
  "East extreme": "Extrême-Orient",
  "East very high": "Est très élevé",
  "East high": "Haut de l'Est",
  "East moderate": "Est modéré",
  "East low": "Bas de l'Est",
  "West extreme": "Extrême ouest",
  "West very high": "Ouest très élevé",
  "West high": "Haut de l'Ouest",
  "West moderate": "Ouest modéré",
  "West low": "Bas de l'Ouest",

  //---------------------- Overlord
  Address: "Adresse",
  "Submitted By": "Soumis par",
  "Date Submitted": "Date d'envoi",

  "Last 30 days": "30 derniers jours",
  All: "Toutes",
  //Completed: "Terminé", // duplicate
  Pending: "En attente",

  PROPERTY_TYPE_ERROR: "Erreur de type de propriété",

  // --------------------- Below are entries added for User Manager

  // Product name constants
  ADDRESS_SEARCH: "Recherche par adresse",
  BUILDING_IMAGERY: "Imagerie du bâtiment",
  PDF_REPORT: "Rapport PDF",
  CONSTRUCTION_PREFILL: "Pré-remplissage construction",
  COMPARABLES: "Comparables",
  MARKET_VALUATION: "Valeur marchande",
  MARKET_RANGE: "Fourchette des valeurs marchandes",

  BRAND: "Marque",

  READONLY_PREFILL: "Restriction des modifications",

  COMPARABLES_RADIUS: "Rayon de recherche",
  COMPARABLES_NUMBER: "Nombre de comparables",
  COMPARABLES_HISTORY: "Historique de la recherche",
  "Past # months": "# derniers mois",

  // user/admin role names
  Role0: "Utilisateur",
  Role1: "Admin. Opta",
  Role2: "Admin. compagnie",
  Role3: "Admin.",

  // API error messages
  Error400: "Échec de la demande API",
  Error401: "Échec de la demande d'autorisation",
  Error403: "Demande non acceptée",
  Error404: "Ressource API non trouvée",
  Error405: "Méthode API non acceptée",
  Error408: "Demande de temps d'arrêt",
  Error409: "Échec de l'opération en raison d'un conflit",
  GroupNameConflict: "Le nom du groupe existe déjà",

  Active: "Actif",
  "Add Group": "Ajouter un groupe",
  "Add Subgroup": "Ajouter un sous-groupe",
  "Add User": "Ajouter un utilisateur",
  Branding: "Image de marque",
  Brand: "Marque",
  Created: "Créé",
  "Email Verified": "Courriel vérifié",
  "First Name": "Prénom",
  Group: "Groupe",
  "Group Name": "Nom du groupe",
  "Last Name": "Nom de famille",
  "No. Users": "Nombre d'utilisateurs",
  Products: "Produits",
  Role: "Rôle",
  Status: "Statut",
  "User Admin": "Admin. utilisateurs",
  Users: "utilisateurs",

  "Manage Group": "Gérer le groupe",
  "Manage User": "Gérer l'utilisateur",
  Subgroups: "Sous-groupes",
  "same as email": "identique au courrier électronique",
  "Inherited products": "Produits hérités",

  // Environmental descriptions
  FLOODSCORE_INFO:
    "Opta Flood Score vous permet d'identifier instantanément les propriétés les plus exposées au risque d'inondation (fluviale et terrestre) avec un aperçu en temps réel du risque relatif de la propriété par rapport à la population générale. Le Flood Score est un score numérique : plus le score est élevé, plus le risque est important.",

  FLOODMAP_INFO:
    "L'imagerie cartographique permet de mieux comprendre les risques d'inondation. Les zones rouges indiquent les zones à haut risque d'inondation. - Changez de vue pour voir 50 ans, 100 ans et 1000 ans.",

  WILDFIRE_INFO:
    "L'indice de feu de forêt du Fire Underwriters Survey (FUS) mesure un certain nombre de facteurs susceptibles de provoquer ou d'aggraver un feu de forêt et le risque de feu de forêt dans la région. Le Wildfire Index est un score numérique : plus le score est élevé, plus le risque est important.",

  EARTHQUAKE_INFO:
    "Les zones CRESTA (Catastrophe Risk Evaluation and Standardizing Target Accumulations) font partie d'un système international de zonage géographique qui aide les professionnels à gérer les risques naturels.",

  // active wildfires questions
  QUEST_ACTIVE_FIRES: "Y a-t-il des “incendies actifs” dans un rayon de 50 km ?",
  QUEST_HOTSPOTS_24:
    "Y a-t-il des “points chauds” (au cours des dernières 24 heures) dans un rayon de 50 km ?",
  QUEST_HOTSPOTS_7:
    "Y a-t-il des “points chauds” (au cours des 7 derniers jours) dans un rayon de 50 km ?",
  QUEST_FIRE_PERIMETERS: "Existe-t-il des “périmètres d'incendie” dans un rayon de 50 km ?",
  QUEST_FIRE_DANGER: "Quel est l'indice de danger d'incendie actuel dans la région ?",

  FLOOD_RISK_VIEW:
    "Vue à 5 km du risque d'inondation, y compris la probabilité d'une perte d'inondation dans des périodes de 50, 100 et 1000 ans.",

  // Flood score not available
  FLOODSCORE_NA:
    "Nous ne sommes malheureusement pas en mesure de fournir un score d'inondation pour cette adresse car elle se situe en dehors de la région inondable que nous avons cartographiée.|Nous pouvons fournir un score d'inondation pour 98% de la population du Canada.",

  FLOODSCORE_CONDO:
    "Le score d'inondation n'est pas actuellement disponible pour les propriétés en copropriété.",

  "Active Wildfires": "Feux de forêt actifs",

  // flood probability
  "Flood Probability": "Probabilité d’inondation",
  // question (with years param)
  QUEST_FLOOD_PROBABILITY: "Probabilité d'une inondation dans les %1 prochaines années?",

  Low: "Faible",
  Moderate: "Modéré",
  High: "Élevé",
  "Very High": "Très élevé",
  Extreme: "Extrême",
  Nil: "Néant",

  "Date and Time of Search:": "Date et heure de la recherche :",

  //---------------------- Support page
  "HOW CAN WE HELP YOU?": "COMMENT POUVONS-NOUS VOUS AIDER ?",
  "Getting Started": "Pour commencer",
  "Login and Property Search": "Connexion et recherche de biens",
  "Construction Features and Edits": "Caractéristiques de construction et modifications",
  "Property Estimator": "Estimateur de biens",
  "Comparables and Valuations+": "Comparables et évaluations",
  "Valuation AVM and Rent AVM": "Evaluation AVM et Rent AVM",
  Definitions: "Définitions",
  "Flood, Wildfire and Earthquake": "Inondations, incendies et tremblements de terre",
};

export default labels;
